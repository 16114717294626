.card-img-card-img-top-slider {
  width: 100%;
  height: 5vh;
  object-fit: contain;
}
.card-notify-badge {
  position: absolute;
  left: -10px;
  top: -15px;
  /* background: #f2d900; */
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: #000;
  padding: 5px 10px;
  font-size: 14px;
}

.logo {
  width: 150px;
  /* height: 50px; */
}

.langugeLi {
  height: 30px;
  cursor: pointer;
}

.linkS {
  cursor: pointer;
}

.current {
  color: #cc2224 !important;
}

.container {
  max-width: 1240px;
}

@media (max-width: 992px) {
  .nav-link {
    font-size: 15px !important;
    text-align: center;
  }
  /* .nav > li {
    text-align: right;
  } */
}

@media (min-width: 768px) {
  .nav-custom-left {
    margin-left: 0% !important;
  }
}

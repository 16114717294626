.tab-image {
  cursor: pointer;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.tab-image:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.row-images {
  padding: 20px;
}
.p-images {
  padding-top: 10px;
}

.tab {
  background: #dcdde1;
  color: #000000;
  /* border-bottom: 1px solid grey !important; */
  /* border-color: none !important; */
}

.tab.active {
  border-top: 2px solid #ff5b5b !important;
  color: #000000 !important;
  background-color: #f5f6fa;
}
.tab:hover {
  background-color: #fff;
  color: #000000 !important;
}

.tab-li {
  margin-right: 2px;
}

.box {
  background: #f5f5f5;
  padding: 30px;
  border: 2px solid #ff5b5b;
  border-radius: 25px;

  /* padding: 20px;
  width: 200px;
  height: 150px; */
}
.sort-options {
  width: auto;
  height: 30px !important;
  font-size: 12px;
}
.control-label {
  font-size: 12px;
  font-weight: normal;
  display: inline-block;
  margin-right: 10px;
  line-height: 2;
}
.sort {
  padding-top: 5px;
}

.summary {
  padding-top: 10px;
}
.col-meta {
  color: #777;
}

.row-divider {
  border-bottom: 1px dotted #e8e8e8;
  padding: 10px 0;
}

.titles-table {
  font-family: 'Montserrat';
}

.border {
  border-width: 2px !important;
}
.home-courses {
  padding-top: 20px !important;
}

#large-button {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.customButton {
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  background-color: #e0314b;
  color: white;
  font-size: 16px;
  border: 1.5px solid #e0314b;
  border-radius: 5px;
  cursor: pointer;
}

.active-button {
  background-color: rgb(25, 26, 24);
  border: 1.5px solid rgb(25, 26, 24);
}

/* .image-card {
  filter: grayscale(90%) !important;
} */
.image-card:hover {
  filter: grayscale(0) !important;
}

.text-card-red {
  color: white !important;
}

.template {
  background-color: #e0314b;
}

.pills {
  background-color: #e0314b !important;
  color: white !important;
}

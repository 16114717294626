/* .slick-slide img {
	width: 100%;
	height: 100vh;
	object-fit: cover;
} */

.slick-prev {
	left: 3% !important;
	z-index: 1;
}
.slick-next {
	right: 3% !important;
	z-index: 1;
}

/* .test {
	padding-top: 90px;
	background-image: url(https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350);
	width: 100%;
	height: 100%;
	object-fit: cover;
} */

.cart {
	padding: 20px;
	background: #315f51;
	color: #fff;
	height: calc(100vh - 100px);
	position: relative;
}

.cartHeader {
	border-bottom: 1px solid #fff;
	margin-bottom: 20px;
}

.cartTitle {
	margin-bottom: 10px;
}

.itemsCount {
	display: block;
	margin-bottom: 20px;
}

.itemsList {
	margin: 0 0 20px 0;
	padding: 0;
	list-style: none;
}

.cartFooter {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	padding: 20px;
}

.subtotal {
	text-align: center;
	margin-bottom: 10px;
}

.subtotalAmount {
	font-size: 24px;
	font-weight: 300;
}

.payBtn {
	display: block;
	margin: 10px 0;
	background: #4caf50;
	color: #fff;
	border: none;
	padding: 10px 8px;
	width: 100%;
	font-weight: 700;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
}

.quote{
    padding-left:15px;
    border-left:4px solid #dcdde1;
    font-family: 'Montserrat';
}
.quote-icon {
    color:#ff5b5b;
    margin-right:5px;
    font-size:18px;
}
.prev{
    color: #fff;
    background-color: #7f8fa6;
    margin-right: 4px;
    font-size:18px;
}
.prev:hover{
    color: #ff5b5b;
}

.next{
    color: #fff;
    background-color: #7f8fa6;
    margin-right: 4px;
    font-size:18px;
}
.next:hover{
    color: #ff5b5b;
}
/* p {
    font-family: 'Montserrat';
    text-align: center;
} */
.source{
    position:relative;
    min-height:80px;
}
.people {
    margin-top:30px;
    margin-left:19px;
    color: #ff5b5b;
}

@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@300;400;500;700&display=swap);
.projectbanner{
    width: 800px;
    height: auto;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
}
.project-section{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

body {
  font-family: 'Montserrat', sans-serif;
}
.backtotop-btn {
  background-color: #000;
  border-color: #000;
}

.logo-universe {
  padding-right: 10px !important;
  width: 130px !important;
  height: 55px !important;
  cursor: pointer;
}
.logo-universe:hover {
  opacity: 0.6 !important;
}
.logo-universe-network {
  padding-right: 10px !important;
  width: 130px !important;
  height: 30px !important;
  cursor: pointer;
}
.logo-universe-network:hover {
  opacity: 0.6 !important;
}

.terms {
  cursor: pointer;
}
.footer {
  background-color: #cc2224 !important;
}
.reserved-rights {
  color: #fff !important;
}
.social-media-icon:hover {
  background-color: grey !important;
}
.link-to-docs:hover {
  color: black !important;
}

/* .modal-footer {
    max-width: none;
    width: 60%;
} */

.label {
	font-family: "Montserrat", sans-serif !important;
}

.legend {
	float: none;
	padding: inherit;
}

.formation-link:hover {
    background-color: rgb(180, 177, 177) !important;
}
.formation-link:hover {
    background-color: rgb(180, 177, 177) !important;
}
.logo {
    width: 150px;
	/* height: 50px; */
}

.langugeLi {
    height: 30px;
    cursor: pointer
}

.linkS{
    cursor: pointer;
}
.table-products {
  font-family: "Montserrat", sans-serif;
}

.certificationBanner {
  padding: 40px !important;
}

.logo {
  width: 150px;
  /* height: 50px; */
}

.langugeLi {
  height: 30px;
  cursor: pointer;
}

.linkS {
  cursor: pointer;
}

.current {
  color: #cc2224 !important;
}

.container {
  max-width: 1240px;
}

@media (max-width: 992px) {
  .nav-link {
    font-size: 15px !important;
    text-align: center;
  }
  /* .nav > li {
    text-align: right;
  } */
}

@media (min-width: 768px) {
  .nav-custom-left {
    margin-left: 0% !important;
  }
}

.logo {
    width: 150px;
	/* height: 50px; */
}

.langugeLi {
    height: 30px;
    cursor: pointer
}

.linkS{
    cursor: pointer;
}

.error-template {
    padding: 40px 15px;
    text-align: center;
}

.error-actions {
    margin-top: 15px;
    margin-bottom: 15px;
}

.error-actions .btn {
    margin-right: 10px;
}

#home {
    padding-bottom: 0px !important;
}
.cartItem {
	display: flex;
	font-size: 14px;
	margin-bottom: 10px;
}

.itemImage {
	width: 50px;
	height: 50px;
	margin-right: 10px;
}

.itemDetails {
	width: 100%;
}

.itemName {
	font-weight: 500;
}

.priceAndQuantity {
	font-weight: 100;
}

.price {
	margin-left: 5px;
}

.removeBtn {
	background: none;
	color: #9e9e9e;
	border: none;
	cursor: pointer;
}

.removeBtn:hover {
	color: #ff5722;
}

.cart {
	padding: 20px;
	background: #315f51;
	color: #fff;
	height: calc(100vh - 100px);
	position: relative;
}

.cartHeader {
	border-bottom: 1px solid #fff;
	margin-bottom: 20px;
}

.cartTitle {
	margin-bottom: 10px;
}

.itemsCount {
	display: block;
	margin-bottom: 20px;
}

.itemsList {
	margin: 0 0 20px 0;
	padding: 0;
	list-style: none;
}

.cartFooter {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	padding: 20px;
}

.subtotal {
	text-align: center;
	margin-bottom: 10px;
}

.subtotalAmount {
	font-size: 24px;
	font-weight: 300;
}

.payBtn {
	display: block;
	margin: 10px 0;
	background: #4caf50;
	color: #fff;
	border: none;
	padding: 10px 8px;
	width: 100%;
	font-weight: 700;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
}

.logo {
    width: 150px;
	/* height: 50px; */
}

.langugeLi {
    height: 30px;
}

.linkS{
    cursor: pointer;
}
.productPage {
	display: flex;
	flex-wrap: wrap;
}

.imageCol {
	width: 400px;
}

.imageCol .productImage {
	max-width: 100%;
}

.infoCol {
	width: 400px;
	padding: 20px;
}

.productImage {
	width: 400px;
}

.price {
	font-size: 32px;
	font-weight: 500;
	margin-bottom: 20px;
	color: #777;
}

.currency {
	font-size: 18px;
}

.addToCartBtn {
	display: block;
	margin: 15px 0;
	background: #222;
	color: #fff;
	padding: 10px;
	text-transform: uppercase;
	border: none;
}

.card-img-top {
  object-fit: scale-down;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#f70505 0%, #8f0808 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #8f0808;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8f0808;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #8f0808;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8f0808;
}

.thankYouPage {
	max-width: 800px;
	margin: auto;
	padding: 30px;
}

.orderTable {
	border-collapse: collapse;
	margin-top: 20px;
	margin-bottom: 20px;
}

.orderTable th,
.orderTable td {
	padding: 10px;
	border: 1px solid #777;
}

.tab-image {
  cursor: pointer;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.tab-image:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.row-images {
  padding: 20px;
}
.p-images {
  padding-top: 10px;
}

.tab {
  background: #dcdde1;
  color: #000000;
  /* border-bottom: 1px solid grey !important; */
  /* border-color: none !important; */
}

.tab.active {
  border-top: 2px solid #ff5b5b !important;
  color: #000000 !important;
  background-color: #f5f6fa;
}
.tab:hover {
  background-color: #fff;
  color: #000000 !important;
}

.tab-li {
  margin-right: 2px;
}

.box {
  background: #f5f5f5;
  padding: 30px;
  border: 2px solid #ff5b5b;
  border-radius: 25px;

  /* padding: 20px;
  width: 200px;
  height: 150px; */
}
.sort-options {
  width: auto;
  height: 30px !important;
  font-size: 12px;
}
.control-label {
  font-size: 12px;
  font-weight: normal;
  display: inline-block;
  margin-right: 10px;
  line-height: 2;
}
.sort {
  padding-top: 5px;
}

.summary {
  padding-top: 10px;
}
.col-meta {
  color: #777;
}

.row-divider {
  border-bottom: 1px dotted #e8e8e8;
  padding: 10px 0;
}

.titles-table {
  font-family: 'Montserrat';
}

.border {
  border-width: 2px !important;
}
.home-courses {
  padding-top: 20px !important;
}

#large-button {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.customButton {
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  background-color: #e0314b;
  color: white;
  font-size: 16px;
  border: 1.5px solid #e0314b;
  border-radius: 5px;
  cursor: pointer;
}

.active-button {
  background-color: rgb(25, 26, 24);
  border: 1.5px solid rgb(25, 26, 24);
}

/* .image-card {
  filter: grayscale(90%) !important;
} */
.image-card:hover {
  -webkit-filter: grayscale(0) !important;
          filter: grayscale(0) !important;
}

.text-card-red {
  color: white !important;
}

.template {
  background-color: #e0314b;
}

.pills {
  background-color: #e0314b !important;
  color: white !important;
}

.quote{
    padding-left:15px;
    border-left:4px solid #dcdde1;
    font-family: 'Montserrat';
}
.quote-icon {
    color:#ff5b5b;
    margin-right:5px;
    font-size:18px;
}
.prev{
    color: #fff;
    background-color: #7f8fa6;
    margin-right: 4px;
    font-size:18px;
}
.prev:hover{
    color: #ff5b5b;
}

.next{
    color: #fff;
    background-color: #7f8fa6;
    margin-right: 4px;
    font-size:18px;
}
.next:hover{
    color: #ff5b5b;
}
/* p {
    font-family: 'Montserrat';
    text-align: center;
} */
.source{
    position:relative;
    min-height:80px;
}
.people {
    margin-top:30px;
    margin-left:19px;
    color: #ff5b5b;
}

.page-item.active .page-link {
  color: #fff !important;
  background: grey !important;
}


.card-img-card-img-top-slider {
  width: 100%;
  height: 15vh;
  object-fit: cover;
}
.card-notify-badge {
  position: absolute;
  left: -10px;
  top: -15px;
  /* background: #f2d900; */
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: #000;
  padding: 5px 10px;
  font-size: 14px;
}

/* .slick-slide img {
	width: 100%;
	height: 100vh;
	object-fit: cover;
} */

.slick-prev {
	left: 3% !important;
	z-index: 1;
}
.slick-next {
	right: 3% !important;
	z-index: 1;
}

/* .test {
	padding-top: 90px;
	background-image: url(https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350);
	width: 100%;
	height: 100%;
	object-fit: cover;
} */

.card {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  /* padding: 14px 80px 18px 36px; */
  cursor: pointer;
}
.card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover !important;
}

.card-img-card-img-top-slider {
  width: 100%;
  height: 5vh;
  object-fit: contain;
}
.card-notify-badge {
  position: absolute;
  left: -10px;
  top: -15px;
  /* background: #f2d900; */
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: #000;
  padding: 5px 10px;
  font-size: 14px;
}

.img-team{
    cursor: pointer;
    border-radius: 30%!important;
}

.swal2-content{
    font-family: 'Montserrat';
}
.brand_logo {
  height: 150px;
  width: 150px;
  background-color: #fff;
}
.brand_logo2 {
  height: 200px;
  width: 230px;
  padding: 25px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  cursor: pointer;
}

.swal-text {
  text-align: justify;
  text-justify: inter-word;
}

/* img {
  margin: auto;
  height: 200px;
  width: 200px;
} */

.slider-partners > div {
  display: grid;
  place-items: center;
  width: 70%;
  margin-top: 50px;
  margin: auto;
  height: 500px;
  padding: 0px;
  /* background: red; */
}
.slider-clients > div {
  display: grid;
  place-items: center;
  width: 70%;
  margin-top: 50px;
  margin: auto;
  height: 500px;
  padding: 0px;
}

.slider-clients > div > img {
  margin: auto;
  height: 200px;
  width: 200px;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.clients {
  background-color: #cc2224;
}

.image-event{
    max-width: 75% !important; 
    /* display: inline !important; */
}

/* .news-section{} */

.rbc-event {
    background-color: #ff5b5b !important;
}

.rbc-event.rbc-selected {
    background-color: #c0392b !important;
}

.rbc-event:focus {
    outline: 5px auto #f39c12 !important;
}

.card-img-top {
    width: 100%;
    height: 25vh;
    object-fit:cover;
}
.card-footer {
    background: transparent;
    border-top: 0px;
    padding-bottom: 2rem;
  }
.home-wrapper2 {
    padding: 50px 0px 10px 0px !important; 
}

.row-title{
    padding-top: 100px;
}

.h3-color{
    color:#fff !important;
}
.quote2{
    padding-left:15px;
    font-family: 'Montserrat';
    font-size:20px;
    border-left:4px solid #dcdde1;
}

.quote-icon2 {
    color:#ff5b5b;
    margin-right:5px;
    font-size:26px;
}


.people2 {
    margin-top:30px;
    margin-left:19px;
    color: #ff5b5b;
    font-size:large;
    font-family: 'Montserrat';
}
.logo {
    width: 150px;
	/* height: 50px; */
}

.langugeLi {
    height: 30px;
    cursor: pointer;
}

.linkS{
    cursor: pointer;
}

.locations{
    cursor: pointer !important;
}
.full-page-loader {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
}

.full-page-loader > img {
	-webkit-animation: 2s infinite heartbeat;
	        animation: 2s infinite heartbeat;
}

@-webkit-keyframes heartbeat {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	25% {
		-webkit-transform: scale(1.05);
		        transform: scale(1.05);
	}

	50% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	75% {
		-webkit-transform: scale(1.05);
		        transform: scale(1.05);
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes heartbeat {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	25% {
		-webkit-transform: scale(1.05);
		        transform: scale(1.05);
	}

	50% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	75% {
		-webkit-transform: scale(1.05);
		        transform: scale(1.05);
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

.full-page-loader {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.full-page-loader>img {
    -webkit-animation: 2s infinite heartbeat;
            animation: 2s infinite heartbeat;
}

@-webkit-keyframes heartbeat {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    25% {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }

    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    75% {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes heartbeat {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    25% {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }

    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    75% {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
.logo {
    width: 150px;
	/* height: 50px; */
}

.langugeLi {
    height: 30px;
    cursor: pointer
}

.linkS{
    cursor: pointer;
}
.container-notice {
  font-family: "Montserrat", sans-serif;
}

/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.logo-universe {
  padding-right: 10px !important;
  width: 130px !important;
  height: 55px !important;
  cursor: pointer;
}
.icon-bar {
  position: fixed !important;
  z-index: 999;
  /* position: fixed; */
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

/* Style the social media icons with color, if you want */
.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #bb0000;
  color: white;
}

.twitter {
  background: #55acee;
  color: white;
}

.google {
  background: #ffa500;
  color: white;
  height: 80px !important;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}


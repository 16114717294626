.projectbanner{
    width: 800px;
    height: auto;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
}
.project-section{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

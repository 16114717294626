@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@300;400;500;700&display=swap");

.label {
	font-family: "Montserrat", sans-serif !important;
}

.legend {
	float: none;
	padding: inherit;
}

.logo-universe {
  padding-right: 10px !important;
  width: 130px !important;
  height: 55px !important;
  cursor: pointer;
}
.logo-universe:hover {
  opacity: 0.6 !important;
}
.logo-universe-network {
  padding-right: 10px !important;
  width: 130px !important;
  height: 30px !important;
  cursor: pointer;
}
.logo-universe-network:hover {
  opacity: 0.6 !important;
}

.terms {
  cursor: pointer;
}
.footer {
  background-color: #cc2224 !important;
}
.reserved-rights {
  color: #fff !important;
}
.social-media-icon:hover {
  background-color: grey !important;
}
.link-to-docs:hover {
  color: black !important;
}

/* .modal-footer {
    max-width: none;
    width: 60%;
} */

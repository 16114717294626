.image-event{
    max-width: 75% !important; 
    /* display: inline !important; */
}

/* .news-section{} */

.rbc-event {
    background-color: #ff5b5b !important;
}

.rbc-event.rbc-selected {
    background-color: #c0392b !important;
}

.rbc-event:focus {
    outline: 5px auto #f39c12 !important;
}

.card-img-top {
    width: 100%;
    height: 25vh;
    object-fit:cover;
}
.card-footer {
    background: transparent;
    border-top: 0px;
    padding-bottom: 2rem;
  }
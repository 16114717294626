.brand_logo {
  height: 150px;
  width: 150px;
  background-color: #fff;
}
.brand_logo2 {
  height: 200px;
  width: 230px;
  padding: 25px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  cursor: pointer;
}

.swal-text {
  text-align: justify;
  text-justify: inter-word;
}

/* img {
  margin: auto;
  height: 200px;
  width: 200px;
} */

.slider-partners > div {
  display: grid;
  place-items: center;
  width: 70%;
  margin-top: 50px;
  margin: auto;
  height: 500px;
  padding: 0px;
  /* background: red; */
}
.slider-clients > div {
  display: grid;
  place-items: center;
  width: 70%;
  margin-top: 50px;
  margin: auto;
  height: 500px;
  padding: 0px;
}

.slider-clients > div > img {
  margin: auto;
  height: 200px;
  width: 200px;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

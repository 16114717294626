.cartItem {
	display: flex;
	font-size: 14px;
	margin-bottom: 10px;
}

.itemImage {
	width: 50px;
	height: 50px;
	margin-right: 10px;
}

.itemDetails {
	width: 100%;
}

.itemName {
	font-weight: 500;
}

.priceAndQuantity {
	font-weight: 100;
}

.price {
	margin-left: 5px;
}

.removeBtn {
	background: none;
	color: #9e9e9e;
	border: none;
	cursor: pointer;
}

.removeBtn:hover {
	color: #ff5722;
}

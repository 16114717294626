/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.logo-universe {
  padding-right: 10px !important;
  width: 130px !important;
  height: 55px !important;
  cursor: pointer;
}
.icon-bar {
  position: fixed !important;
  z-index: 999;
  /* position: fixed; */
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

/* Style the social media icons with color, if you want */
.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #bb0000;
  color: white;
}

.twitter {
  background: #55acee;
  color: white;
}

.google {
  background: #ffa500;
  color: white;
  height: 80px !important;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

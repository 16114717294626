.logo {
    width: 150px;
	/* height: 50px; */
}

.langugeLi {
    height: 30px;
    cursor: pointer
}

.linkS{
    cursor: pointer;
}
.home-wrapper2 {
    padding: 50px 0px 10px 0px !important; 
}

.row-title{
    padding-top: 100px;
}

.h3-color{
    color:#fff !important;
}
.quote2{
    padding-left:15px;
    font-family: 'Montserrat';
    font-size:20px;
    border-left:4px solid #dcdde1;
}

.quote-icon2 {
    color:#ff5b5b;
    margin-right:5px;
    font-size:26px;
}


.people2 {
    margin-top:30px;
    margin-left:19px;
    color: #ff5b5b;
    font-size:large;
    font-family: 'Montserrat';
}